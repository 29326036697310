<template>
    <div>
        <counter></counter>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import counter from "@/views/partials/customer-impact/counter.vue";
import URL from "@/util/url.js";
export default {
    mounted(){
        //we set the data set when arriving at this portion of the app
        this.getData().then( () =>{

            //we are in the origintal tool here.  Not TVD or Goals
            //hashes in the URL dictates the default header choices
            //Once we have the data we can go about setting this
            this.handleArrivingUrlHash();
        });
    },
    computed : {
        ...mapGetters([
            'iframe'
        ])
    },

    methods : {
        ...mapActions([
            'getData', //initial data from json
            'setApplicationState',
            'setTechnologyState',
            'setRegionState',
            'setMetricUnitState',
            'setImpactAreaState',
            'setPeriodState'

        ]),

        handleArrivingUrlHash : function(){

            if(!URL.hash.application){
                this.setApplicationState('all-applications');
            }

            if(URL.hash.application){
                this.setApplicationState(URL.hash.application)
            }

            this.$nextTick( () => {
                if(URL.hash.technology){
                    this.setTechnologyState(URL.hash.technology)
                }

                this.$nextTick( () => {
                    if(URL.hash.location){
                        this.setRegionState(URL.hash.location);
                    }
                    if(URL.hash.unit){
                            this.setMetricUnitState(URL.hash.unit);
                    }
                    if(URL.hash.impactArea){
                        this.setImpactAreaState(URL.hash.impactArea);
                    }

                    if(URL.hash.period){
                        this.setPeriodState(URL.hash.period);
                    }
                })
            })

        },

    },
    watch : {
        iframe : function(isIframe){
            if(isIframe == false){
                 document.getElementsByTagName('body')[0].className+=' fullscreen';
            }
        }
    },
    components : {
        counter
    }
}
</script>
